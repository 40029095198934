<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />
    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row align-items-center">
              <div class="col-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-auto">
                    <h5 class="card-title font-weight-semibold">Kajian Pra Operasi</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="anamnesisSubjektif">Subjektif <strong class="text-danger">*</strong></label>
                  <b-form-textarea v-model="row.aranpop_subjektif" rows="3" class="form-control"></b-form-textarea>
                  <VValidate 
                    name="Subjektif" 
                    v-model="row.aranpop_subjektif" 
                    :rules="toValidate(mrValidation.aranpop_subjektif)"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="anamnesisObjektif">Objektif <strong class="text-danger">*</strong></label>
                  <b-form-textarea v-model="row.aranpop_objektif" rows="3" class="form-control"></b-form-textarea>
                  <VValidate 
                    name="Objektif" 
                    v-model="row.aranpop_objektif" 
                    :rules="toValidate(mrValidation.aranpop_objektif)"
                  />
                </div>
              </div>
              <div class="col-md-6" v-if="row.aranpop_arano_id">
                <ICD10Operasi title="Diagnosa ICD 10 Pra-Operasi" type="KajianPraBedahPraOperasi" :idOp="row.aranpop_arano_id" />
                <div class="form-group p-3 custom-diagnosa">
                  <label for="diagnosisPraoperasi">Diagnosis Pra-Operasi Lainnya<strong class="text-danger">*</strong></label>
                  <b-form-textarea v-model="row.aranpop_pra_operasi" rows="3" class="form-control"></b-form-textarea>
                  <VValidate 
                    name="Diagnosis Pra-Operasi" 
                    v-model="row.aranpop_pra_operasi" 
                    :rules="toValidate(mrValidation.aranpop_pra_operasi)"
                  />
                </div>
              </div>
              <div class="col-md-6" v-if="row.aranpop_arano_id">
                <ICD10Operasi title="Diagnosa ICD 10 Operasi" type="KajianPraBedahOperasi" :idOp="row.aranpop_arano_id" />
                <div class="form-group p-3 custom-diagnosa">
                  <label for="diagnosisOperasi">Diagnosis Operasi Lainnya<strong class="text-danger">*</strong></label>
                  <b-form-textarea v-model="row.aranpop_diagnosis_operasi" rows="3" class="form-control"></b-form-textarea>
                  <VValidate 
                    name="Diagnosis Operasi" 
                    v-model="row.aranpop_diagnosis_operasi" 
                    :rules="toValidate(mrValidation.aranpop_diagnosis_operasi)"
                  />
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="posisiPasien">Posisi Pasien <strong class="text-danger">*</strong></label>
                  <v-select placeholder="-- Pilih Salah Satu --" v-model="row.aranpop_posisi_pasien"
                  :options="Config.mr.posisiPasienRanap" label="text"
                  :clearable="true" :reduce="v=>v.value"></v-select>
                  
                  <VValidate 
                    name="Posisi Pasien" 
                    v-model="row.aranpop_posisi_pasien" 
                    :rules="toValidate(mrValidation.aranpop_posisi_pasien)"
                  />
                  <template v-if="row.aranpop_posisi_pasien == 'Lainnya'">
                    <b-form-input v-model="row.aranpop_posisi_pasien_lainnya" type="text"
                    class="form-control" />
                    <VValidate 
                    name="Posisi Pasien Lainnya" 
                    v-model="row.aranpop_posisi_pasien_lainnya" 
                    :rules="toValidate(mrValidation.aranpop_posisi_pasien_lainnya)"
                  />
                  </template>
                </div>
              </div>
              <div class="col-md-8 col-lg-5">
                <div class="form-group">
                  <label for="estimasiWaktu">Estimasi Waktu<strong class="text-danger">*</strong></label>
                  <div class="form-row">
                    <div class="col-md-5">
                      <b-form-input id="gula_darah" :formatter="number"
                        v-model="row.aranpop_est" type="text"
                        class="form-control" />

                      <VValidate 
                      name="Estimasi Waktu" 
                      v-model="row.aranpop_est" 
                      :rules="toValidate(mrValidation.aranpop_est)"/>
                    </div>
                    <div class="col-md-6">
                     <v-select placeholder="-- Pilih Salah Satu --" v-model="row.aranpop_type_est"
                      :options="Config.mr.jamOrMenit" label="text"
                      :clearable="true" :reduce="v=>v.value"></v-select>
                      
                      <VValidate 
                      name="Type Estimasi Waktu" 
                      v-model="row.aranpop_type_est" 
                      :rules="toValidate(mrValidation.aranpop_type_est)"/>
                      
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-7">
                <div class="form-group">
                  <label for="catatanKhusus">Catatan Khusus</label>
                  <b-form-textarea v-model="row.aranpop_catatan_khusus" rows="3" class="form-control"></b-form-textarea>
                  <!--
                  <VValidate 
                    name="Catatan Khusus" 
                    v-model="row.aranpop_catatan_khusus" 
                    :rules="toValidate(mrValidation.aranpop_catatan_khusus)"
                  />
                  -->
                </div>
              </div>
            </div>
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Lokasi Operasi pada Pasien</h6>
              </div>
              <div class="card-body p-3">
                <div class="alert alert-info">
                  <div class="d-flex">
                    <i class="icon-info22 mt-1"></i>
                    <span class="ml-2">Klik pada bagian tubuh pasien untuk menandai lokasi operasi</span>
                  </div>
                </div>
                <div class="row g-3">
                  <div class="col-lg-4">
                    <div class="row g-2">
                      <div class="col-md-6 col-lg-12">
                        <label for="">Tubuh (Depan - Belakang)</label>
                        <div class="op-loc" id="aranpop_loc_tubuh_depan">
                          <a :id="`aranpop_loc_tubuh_depan-${k}`" v-for="(v,k) in (row.aranpop_loc_tubuh_depan||[])" :key="k+'aranpop_loc_tubuh_depan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                            <b-popover :target="`aranpop_loc_tubuh_depan-${k}`" triggers="hover" placement="top">
                              <template>
                                <div class="text-center">
                                  <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                  <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                  <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_tubuh_depan')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                  <i class='icon-trash-alt'></i></a>
                                </div>
                              </template>
                            </b-popover>
                            
                            <b-modal
                              v-model="v.isOpen"
                              :size="'sm'"
                              no-close-on-backdrop
                              ok-only
                              :title="'Tuliskan info terkait'" 
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                  </b-form-textarea>
                                </div>
                              </div>
                            </b-modal>
                          </a>

                          

                          <img :src="row.ap_gender == 1 ? assetLocal('global_assets/images/operation-location/op-male-front-back.png') : assetLocal('global_assets/images/operation-location/op-female-front-back.png')" alt="">
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-12">
                        <label for="">Tubuh (Kanan - Kiri)</label>
                        <div class="op-loc" id="aranpop_loc_tubuh_samping">
                          <a :id="`aranpop_loc_tubuh_samping-${k}`" v-for="(v,k) in (row.aranpop_loc_tubuh_samping||[])" :key="k+'aranpop_loc_tubuh_samping'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                            <b-popover :target="`aranpop_loc_tubuh_samping-${k}`" triggers="hover" placement="top">
                              <template>
                                <div class="text-center">
                                  <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                  <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                  <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_tubuh_samping')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                  <i class='icon-trash-alt'></i></a>
                                </div>
                              </template>
                            </b-popover>
                            
                            <b-modal
                              v-model="v.isOpen"
                              :size="'sm'"
                              no-close-on-backdrop
                              ok-only
                              :title="'Tuliskan info terkait'" 
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                  </b-form-textarea>
                                </div>
                              </div>
                            </b-modal>
                          </a>

                          <img :src="row.ap_gender == 1 ? assetLocal('global_assets/images/operation-location/op-male-right-left.png') : assetLocal('global_assets/images/operation-location/op-female-right-left.png')" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 border-left">
                    <div class="row gy-2">
                      <div class="col-12">
                        <div class="row g-1">
                          <div class="col-md-6">
                            <label for="">Kepala (Depan - Belakang)</label>
                            <div class="op-loc" id="aranpop_loc_kepala_depan">
                              <a :id="`aranpop_loc_kepala_depan-${k}`" v-for="(v,k) in (row.aranpop_loc_kepala_depan||[])" :key="k+'aranpop_loc_kepala_depan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                                <b-popover :target="`aranpop_loc_kepala_depan-${k}`" triggers="hover" placement="top">
                                  <template>
                                    <div class="text-center">
                                      <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                      <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                      <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_kepala_depan')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                      <i class='icon-trash-alt'></i></a>
                                    </div>
                                  </template>
                                </b-popover>
                                
                                <b-modal
                                  v-model="v.isOpen"
                                  :size="'sm'"
                                  no-close-on-backdrop
                                  ok-only
                                  :title="'Tuliskan info terkait'" 
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                      </b-form-textarea>
                                    </div>
                                  </div>
                                </b-modal>
                              </a>
                              <img :src="assetLocal('global_assets/images/operation-location/op-head-front-back.png')" alt="">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="">Kepala (Kanan - Kiri)</label>
                            <div class="op-loc" id="aranpop_loc_kepala_samping">
                              <a :id="`aranpop_loc_kepala_samping-${k}`" v-for="(v,k) in (row.aranpop_loc_kepala_samping||[])" :key="k+'aranpop_loc_kepala_samping'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                                <b-popover :target="`aranpop_loc_kepala_samping-${k}`" triggers="hover" placement="top">
                                  <template>
                                    <div class="text-center">
                                      <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                      <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                      <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_kepala_samping')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                      <i class='icon-trash-alt'></i></a>
                                    </div>
                                  </template>
                                </b-popover>
                                
                                <b-modal
                                  v-model="v.isOpen"
                                  :size="'sm'"
                                  no-close-on-backdrop
                                  ok-only
                                  :title="'Tuliskan info terkait'" 
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                      </b-form-textarea>
                                    </div>
                                  </div>
                                </b-modal>
                              </a>
                              <img :src="assetLocal('global_assets/images/operation-location/op-head-left-right.png')" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row g-1">
                          <div class="col-md-6">
                            <label for="">Punggung Tangan (Kiri - Kanan)</label>
                            <div class="op-loc" id="aranpop_loc_punggung_tangan">
                              <a :id="`aranpop_loc_punggung_tangan-${k}`" v-for="(v,k) in (row.aranpop_loc_punggung_tangan||[])" :key="k+'aranpop_loc_punggung_tangan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                                <b-popover :target="`aranpop_loc_punggung_tangan-${k}`" triggers="hover" placement="top">
                                  <template>
                                    <div class="text-center">
                                      <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                      <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                      <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_punggung_tangan')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                      <i class='icon-trash-alt'></i></a>
                                    </div>
                                  </template>
                                </b-popover>
                                
                                <b-modal
                                  v-model="v.isOpen"
                                  :size="'sm'"
                                  no-close-on-backdrop
                                  ok-only
                                  :title="'Tuliskan info terkait'" 
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                      </b-form-textarea>
                                    </div>
                                  </div>
                                </b-modal>
                              </a>
                              <img :src="assetLocal('global_assets/images/operation-location/op-backhand-left-right.png')" alt="">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="">Telapak Tangan (Kiri - Kanan)</label>
                            <div class="op-loc" id="aranpop_loc_telapak_tangan">
                              <a :id="`aranpop_loc_telapak_tangan-${k}`" v-for="(v,k) in (row.aranpop_loc_telapak_tangan||[])" :key="k+'aranpop_loc_telapak_tangan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                                <b-popover :target="`aranpop_loc_telapak_tangan-${k}`" triggers="hover" placement="top">
                                  <template>
                                    <div class="text-center">
                                      <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                      <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                      <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_telapak_tangan')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                      <i class='icon-trash-alt'></i></a>
                                    </div>
                                  </template>
                                </b-popover>
                                
                                <b-modal
                                  v-model="v.isOpen"
                                  :size="'sm'"
                                  no-close-on-backdrop
                                  ok-only
                                  :title="'Tuliskan info terkait'" 
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                      </b-form-textarea>
                                    </div>
                                  </div>
                                </b-modal>
                              </a>
                              <img :src="assetLocal('global_assets/images/operation-location/op-palm-left-right.png')" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row g-1">
                          <div class="col-md-6">
                            <label for="">Punggung Kaki (Kiri - Kanan)</label>
                            <div class="op-loc" id="aranpop_loc_punggung_kaki">
                              <a :id="`aranpop_loc_punggung_kaki-${k}`" v-for="(v,k) in (row.aranpop_loc_punggung_kaki||[])" :key="k+'aranpop_loc_punggung_kaki'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                                <b-popover :target="`aranpop_loc_punggung_kaki-${k}`" triggers="hover" placement="top">
                                  <template>
                                    <div class="text-center">
                                      <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                      <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                      <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_punggung_kaki')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                      <i class='icon-trash-alt'></i></a>
                                    </div>
                                  </template>
                                </b-popover>
                                
                                <b-modal
                                  v-model="v.isOpen"
                                  :size="'sm'"
                                  no-close-on-backdrop
                                  ok-only
                                  :title="'Tuliskan info terkait'" 
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                      </b-form-textarea>
                                    </div>
                                  </div>
                                </b-modal>
                              </a>
                              
                              <img :src="assetLocal('global_assets/images/operation-location/op-instep-left-right.png')" alt="">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="">Punggung Tangan (Kanan - Kiri)</label>
                            <div class="op-loc" id="aranpop_loc_telapak_kaki">
                              <a :id="`aranpop_loc_telapak_kaki-${k}`" v-for="(v,k) in (row.aranpop_loc_telapak_kaki||[])" :key="k+'aranpop_loc_telapak_kaki'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                                <b-popover :target="`aranpop_loc_telapak_kaki-${k}`" triggers="hover" placement="top">
                                  <template>
                                    <div class="text-center">
                                      <span>{{v.value?v.value:'Tambahkan informasi, atau hapus titik lokasi'}}</span><hr class='my-2'/>
                                      <a href='javascript:;' @click.prevent.stop="v.isOpen = true" class='btn btn-sm btn-icon rounded-round btn-info op-loc-modal btn-loc-modal mr-1'><i class='icon-pencil'></i></a>
                                      <a href='javascript:;' @click.prevent.stop="removeAnat(k,'aranpop_loc_telapak_kaki')" class='btn btn-sm btn-icon rounded-round btn-danger btn-loc-modal'>
                                      <i class='icon-trash-alt'></i></a>
                                    </div>
                                  </template>
                                </b-popover>
                                
                                <b-modal
                                  v-model="v.isOpen"
                                  :size="'sm'"
                                  no-close-on-backdrop
                                  ok-only
                                  :title="'Tuliskan info terkait'" 
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-form-textarea v-model="v.value" rows="3" placeholder="Isi Informasi Lokasi Operasi" class="form-control">
                                      </b-form-textarea>
                                    </div>
                                  </div>
                                </b-modal>
                              </a>
                              <img :src="assetLocal('global_assets/images/operation-location/op-foot-right-left.png')" alt="">                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body mt-2">
                <div>
                  <label for="deskripsiSingkat">
                    <span>Deskripsi Singkat</span>
                    <small class="form-text text-secondary mt-0">Isi apabila terdapat bagian yang tidak dapat ditandai</small>
                  </label>
                  <b-form-textarea v-model="row.aranpop_deskripsi_singkat" rows="3" class="form-control"></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<style>
  img {
    max-width: 100%;
  }
</style>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import ICD10Operasi from '@/components/Ranap/ICD10Operasi.vue'

export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapKajianPraBedah',
      elemOp: [
        'aranpop_loc_tubuh_depan',
        'aranpop_loc_tubuh_samping',
        'aranpop_loc_kepala_depan',
        'aranpop_loc_kepala_samping',
        'aranpop_loc_punggung_tangan',
        'aranpop_loc_telapak_tangan',
        'aranpop_loc_punggung_kaki',
        'aranpop_loc_telapak_kaki'
      ],
      
      patientData: {},
      loading: {
          patientInfo: false,
      },
    }
  },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()

    setTimeout(()=>{
      this.setPosOperasi() 
    },1000)    
  },
  components:{
    PatientInfo,ICD10Operasi
  },
  methods: {
    removeAnat(k, field) {
      this.row[field].splice(k, 1)
    },

    setPosOperasi(){
      let self = this
      
      for(let i = 0; i < (self.elemOp||[]).length; i++){
        let posX = false
        let posY = false
        
        $("body").mousemove(function (e) {
        if (!$("body").hasClass("active")) $("body").addClass("active")
          if ($(`#${self.elemOp[i]}`).length) {
              posX = parseInt(e.pageX - ($(`#${self.elemOp[i]}`).offset().left))
              posY = parseInt(e.pageY - ($(`#${self.elemOp[i]}`).offset().top))
          }
        })

        $(`#${self.elemOp[i]}`).on('click', function (e) {
          e.stopPropagation()
          if (self.row[`${self.elemOp[i]}`]) {
              self.row[`${self.elemOp[i]}`].push({
                  x: (posX / $(this).width()) * 100,
                  y: (posY / $(this).height()) * 100,
                  value: '',
                  isOpen: false,
                  isReversed: posX > 300 ? true : false,
                  reverseCount: `-111%`
              })
          }
        })
      }
    },

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },

    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiDokterOperator'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    },
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.row.arankm_response_gcs = v
        },250)
    },
  }
}
</script>