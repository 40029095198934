<template>
  <div class="content-wrapper" v-if="isList">
    <div class="content pt-0">
      <bo-page-title />
      <div class="card">
        <div class="bg-white card-header">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="form-row align-items-center">
                <div class="col-md-auto col-lg-4">
                  <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                </div>
                <div class="col-md">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
                        :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
                        :ranges="datePickerConfig.ranges" @update="updateValues">
                        <template v-slot:input="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </template>
                      </date-range-picker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-header">
          <div class="row">
            <div class="col-md-8 col-lg-9">
              <div class="row">
                <div class="col-md-auto">
                  <div class="form-group mb-0">
                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                      class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i
                        class="icon-spinner11"></i></button>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row gx-1">
                    <div class="col-md-6 col-lg-3">
                      <b-form-group class="mb-0">
                        <v-select disabled placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"
                          :options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
                      </b-form-group>
                    </div>

                    <div class="col-md-6 col-lg-3">
                      <b-form-group class="mb-0">
                        <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status"
                          :options="mOperasiStatus" label="text" :reduce="v=>v.value"></v-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                <input class="form-control" v-model="filter.search" @input="doSearch"
                  placeholder="Ketik Nama/No. RM Pasien" />
                <div class="form-control-feedback">
                  <i class="icon-search4 text-indigo"></i>
                </div>

                <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
                  @click="doResetData()">Reset</b-button>
              </div>
            </div>
          </div>
        </div>



        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
            <thead>
              <tr>
                <th>NO.</th>
                <th class="tbl-sticky-col tsc-left" style="width: 115px;">NO. KEDATANGAN</th>
                <th class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">NAMA/NO KTP</th>
                <th>DOKTER DPJP</th>
                <th>NO RM</th>
                <th>TANGGAL LAHIR</th>
                <th>USIA</th>
								<th>TINDAKAN OPERASI</th>
								<th>TINDAKAN ANESTESI</th>
                <th>DIAGNOSA</th>
                <th>JADWAL OPERASI</th>
                <th>DOKTER OPERATOR</th>
                <th>DOKTER ANESTESI</th>
                <th>PERAWAT ANESTESI</th>
                <th>STATUS</th>
                <th style="min-width: 100px;" class="tbl-sticky-col tsc-right tsc-right-first">AKSI</th>
              </tr>
            </thead>

            <tbody v-if="(dataList||[]).length">
              <template v-for="(v,k) in (dataList||[])">
                <tr :key="k" :class="!v.aranr_ppjp && !v.aranr_perawat_pelaksana ? 'table-warning':''">
                  <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                  <td class="tbl-sticky-col tsc-left">
                    <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                  </td>
                  <td class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">
                    <div>
                      <a href="javascript:;" @click="toDetail(v.aranr_id)" class="font-weight-semibold border-bottom"
                        v-b-tooltip.hover title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                      <br>
                      {{v.ap_code||"-"}}</div>
                    <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                  </td>
                  <td>{{v.bu_full_name||"-"}}</td>
                  <td>
                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                  </td>
                  <td>
                    <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <div class="font-weight-semibold">
                      {{v.ap_usia_with_ket || "-"}}
                    </div>
                  </td>
                  <td>
                    {{v.arano_tindakan_operasi||"-"}}
                  </td>
									<td>{{v.arano_jenis_new||v.arano_jenis||"-"}}</td>
                  <td>
                    {{v.arano_diagnosa||"-"}}                  
                  </td>
                  <td>
                    {{v.arano_jadwal_operasi | moment("DD MMMM YYYY, HH:mm")}} WIB
                  </td>
                  <td>
                    <span v-if="!(v.dokter_operator||[]).length"> - </span>
                    <ul v-else>
                      <li v-for="(vk,kk) in (v.dokter_operator||[])" :key="kk+'s'">{{vk}}</li>
                    </ul>
                  </td>
                  <td>
                    {{v.dokter_anastesi||"-"}}
                  </td>
                  <td>
                    {{v.perawat_anestesi||"-"}}
                  </td>
                  <td>
                    <div :class="`badge ${v.aranos_color} mb-1`">{{v.aranos_name||"-"}}</div>
                    <div v-if="v.arano_is_implant == 'Y'" :class="`badge bg-danger mb-1`">Implant</div>
										<div v-if="v.arano_is_implant == 'B'" v-b-tooltip.hover :title="v.arano_implant_cancel_reason" :class="`badge bg-danger mb-1`">Implant Dibatalkan</div>
                    <div v-if="v.arano_is_penyakit_menular == 'Y'" :class="`badge bg-danger mb-1`" v-b-tooltip.hover
                      :title="v.mranpm_name">Penyakit Menular</div>
                  </td>
                  <td class="tbl-sticky-col tsc-right tsc-right-first text-center">
                    <a href="javascript:;" @click="toDetail(v.aranr_id)" v-if="moduleRole('view')"
                      class="btn btn-icon rounded-round btn-sm alpha-info border-info" v-b-tooltip.hover
                      title="Lihat Detail">
                      <i class="icon-file-eye"></i>
                    </a>

                    <template v-if="v.arano_status != 99">
                    <a href="javascript:;" v-if="moduleRole('jadwal_operasi') && v.arano_status < 4"
                      @click="jadwalkanOperasi(v)"
                      class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800 mt-1"
                      v-b-tooltip.hover.noninteractive
                      :title="v.arano_status >= sOpTerjadwal ? 'Ubah Jadwal Operasi' : 'Jadwalkan Operasi'">
                      <i class="fa-solid fa-calendar"></i>
                    </a>

                    <a href="javascript:;" @click="doOpenKajianOperasi(v)"
                      class="btn btn-icon rounded-round btn-sm alpha-info border-info mt-1" v-b-tooltip.hover
                      title="Isi Form Operasi">
                      <i class="icon-file-plus"></i>
                    </a>

                    <a href="javascript:;" @click="toModalPemeriksaan(v)"
                      v-if="moduleRole('pemeriksaan_jaringan_tubuh') && v.to_pa"
                      class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800 mt-1"
                      v-b-tooltip.hover title="Form Pemeriksaan Jaringan Tubuh">
                      <i class="fa-solid fa-hospital-user text-danger"></i>
                    </a>
                    </template>

                  </td>

                </tr>
              </template>
            </tbody>
            <tbody v-if="data.data.length == 0">
              <tr>
                <td colspan="99">
                  <div class="text-center">
                    <h4 align="center"><span
                        v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
                        tidak ditemukan</span>
                      <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!data.data">
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
            </tbody>
          </table>


          <div
            :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
            <a href="javascript:;" data-scroll="left"
              class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-left"></i>
            </a>
            <a href="javascript:;" data-scroll="right"
              class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-right"></i>
            </a>
          </div>
        </div>

        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
          <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
        </b-card-footer>


        <b-modal v-model="openKajianOperasi" id="KajianOperasi" size="md" centered title="Kajian Operasi" hide-footer>
          <div class="row">
            <div class="col-md-6 mb-2" v-if="moduleRole('kajian_pra_bedah')">
              <a href="javascript:;" @click="toMenu(rowEdit,'KajianPraBedah')" class="btn_cta_persalinan">
                {{rowEdit.arano_done_pra_operasi == 'Y' ? 'Ubah Kajian Pra Bedah':'Buat Kajian Pra Bedah'}} </a>
            </div>
            <div class="col-md-6" v-if="moduleRole('form_checklist_keselamatan_pasien')">
              <a href="javascript:;" @click="toMenu(rowEdit,'RanapCeklistKeselamatanAnestesi')"
                class="btn_cta_persalinan">
                {{rowEdit.arano_done_checklist_anestesi == 'Y' ? 'Ubah ':'Buat '}}
                Checklist Keselamatan Pasien </a>
            </div>
            <div class="col-md-6" v-if="moduleRole('laporan_operasi')">
              <a href="javascript:;" @click="toMenu(rowEdit,'RanapLaporanOperasi')" class="btn_cta_persalinan">
                {{rowEdit.arano_done_laporan_operasi == 'Y' ? 'Ubah ':'Buat '}} Laporan Operasi </a>
            </div>
          </div>
        </b-modal>
      </div>
    </div>


    <validation-observer ref="VFormSubmitPA">
      <b-modal v-model="openPemeriksaan" ok-title="Submit" @ok.prevent="submitJadwalPA" size="lg" no-close-on-backdrop
        :title="'Pemeriksaan Jaringan Tubuh'">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Diterima Tanggal<small class="txt_mandatory">*</small></label>
              <div class="input-group col-md-10">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-calendar22"></i></span>
                </div>
                <datepicker :clear-button="true" input-class="form-control transparent h-38"
                  calendar-class="my-datepicker" v-model="rowPA.aranpjt_tanggal_diterima">
                </datepicker>
                <vue-timepicker manual-input format="HH:mm" input-class="form-control"
                  v-model="rowPA.aranpjt_waktu_diterima">
                </vue-timepicker>
              </div>

              <VValidate name="Diterima Tanggal" v-model="rowPA.aranpjt_tanggal_diterima" :rules="{required : 1}" />

              <VValidate name="Diterima Waktu" v-model="rowPA.aranpjt_waktu_diterima" :rules="{required : 1}" />
            </div>
          </div>
          <!-- Tempat Rujukan -->
          <div class="col-md-6">
            <div class="form-group">
              <label>Tempat Rujukan</label>
              <b-form-input v-model="rowPA.aranpjt_no" type="text" name="name" class="form-control" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="bg_card_blue">
              <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>No. Reg</h4>
                        <p>{{rowEdit.aranr_reg_code||"-"}}</p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{rowEdit.ap_code||"-"}}</p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{rowEdit.ap_fullname||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Tempat, Tanggal Lahir </h4>
                        <p>{{rowEdit.ap_pob||"-"}},
                          <strong class="font-weight-semibold">{{rowEdit.ap_dob | moment("DD MMM YYYY")}}</strong>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Usia</h4>
                        <p class="font-weight-semibold">
                          {{rowEdit.ap_usia_with_ket || "-"}}<br />({{rowEdit.ap_gol_usia||"-"}})</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jenis Kelamin</h4>
                        <p class="font-weight-semibold">{{rowEdit.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-group ">
              <label>Jaringan tubuh ini didapat dengan <strong class="text-danger">*</strong></label>
              <div>
                <b-form-checkbox-group class="checkbox-block" v-model="rowPA.aranpjt_jaringan_tubuh"
                  :options="Config.mr.mPemeriksaanTubuhJaringan" :name="'aranpjt_jaringan_tubuh'" />

                <div class="ml-2" v-if="(rowPA.aranpjt_jaringan_tubuh||[]).indexOf('Lainnya') != -1">
                  <b-form-input v-model="rowPA.aranpjt_jaringan_tubuh_text" type="text" class="form-control mt-1" />
                  <VValidate :name="'Jaringan tubuh ini didapat dengan'" v-model="rowPA.aranpjt_jaringan_tubuh_text"
                    :rules="{required: 1}" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Lokasi Pengambilan <strong class="text-danger">*</strong></label>
                  <b-form-textarea v-model="rowPA.aranpjt_berasal_dari_alat" class="form-control" rows="3">
                  </b-form-textarea>
                  <VValidate name="Lokasi Pengambilan" v-model="rowPA.aranpjt_berasal_dari_alat"
                    :rules="{required:1, min:3, max:128}" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group ">
                  <label>Direndam dalam <strong class="text-danger">*</strong></label>
                  <b-form-checkbox-group class="checkbox-block" v-model="rowPA.aranpjt_direndam_dalam"
                    :options="Config.mr.mPemeriksaanTubuhDirendam" :name="'aranpjt_direndam_dalam'" />

                  <div class="ml-2" v-if="(rowPA.aranpjt_direndam_dalam||[]).indexOf('Lainnya') != -1">
                    <b-form-input v-model="rowPA.aranpjt_direndam_dalam_text" type="text" class="form-control mt-1" />
                    <VValidate :name="'Direndam dalam'" v-model="rowPA.aranpjt_direndam_dalam_text"
                      :rules="{required: 1}" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10">
                <div class="form-group">
                  <label>Jaringan Keluar Dari Tubuh Jam<small class="txt_mandatory">*</small></label>
                  <div class="input-group col-md-10 pl-0">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                    </div>
                    <datepicker :clear-button="true" input-class="form-control transparent h-38"
                      calendar-class="my-datepicker" v-model="rowPA.aranpjt_tanggal_jaringan_keluar">
                    </datepicker>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control"
                      v-model="rowPA.aranpjt_jam_jaringan_keluar">
                    </vue-timepicker>
                  </div>

                  <VValidate name="Jaringan Keluar Tanggal" v-model="rowPA.aranpjt_tanggal_jaringan_keluar" :rules="{required : 1}" />

                  <VValidate name="Jaringan Keluar Waktu" v-model="rowPA.aranpjt_jam_jaringan_keluar" :rules="{required : 1}" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Keterangan Klinis <strong class="text-danger">*</strong></label>
              <b-form-textarea v-model="rowPA.aranpjt_keterangan_klinis" class="form-control" rows="3">
              </b-form-textarea>
              <VValidate name="Keterangan Klinis" v-model="rowPA.aranpjt_keterangan_klinis"
                :rules="{required:1, min:3, max:1500}" />
              <small class="text-mutued">*Jika mengiirimkan kerokan hendaknya disebut tanggal haid yang
                terakhir</small>
            </div>
          </div>

          <div class="col-md-6" v-if="rowPA.aranpjt_arano_id">
            <ICD10Operasi title="Diagnosa Klinis" type="KajianPAKlinis" :idOp="rowPA.aranpjt_arano_id" />
            <div class="form-group p-3 custom-diagnosa">
              <label for="">Diagnosa Klinis Lainnya
              <strong v-if="isReqKajianPAKlinis" class="text-danger">*</strong></label>
              <b-form-textarea v-model="rowPA.aranpjt_diagnosa_klinis" class="form-control" rows="3"></b-form-textarea>
              <VValidate v-if="isReqKajianPAKlinis" name="Diagnosa Klinis" v-model="rowPA.aranpjt_diagnosa_klinis"
                :rules="{required:1, min:3, max:128}" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="">Nama Penanda Tangan <strong class="text-danger">*</strong></label>
            <b-form-input v-model="rowPA.aranpjt_nama_ttd" type="text" class="form-control" />
            <VValidate name="Nama Penanda Tangan" v-model="rowPA.aranpjt_nama_ttd"
              :rules="{required:1, min:3, max:128}" />

            <div class="signing-element mt-1">
              <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdNama" />
              <div class="mt-2">
                <button type="button" @click="undoTTD('ttdNama','aranpjt_ttd')"
                  class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                  <b><i class="icon-cross3"></i></b>
                  <span>Clear</span>
                </button>

                <button type="button" @click="output('ttdNama','aranpjt_ttd')"
                  class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                  <b><i class="icon-checkmark2"></i></b>
                  <span>Simpan</span>
                </button>
              </div>

              <VValidate name="Tanda Tangan Keluarga" v-model="rowPA.aranpjt_ttd" :rules="{required:1}" />
            </div>
          </div>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <b-button variant="secondary" @click="cancel()">
            Tutup
          </b-button>
          <b-button variant="success" @click="ok()">
            Simpan
          </b-button>
        </template>
      </b-modal>
    </validation-observer>


    <validation-observer ref="VFormSubmitJadwalOperasi">
      <b-modal v-model="openJadwalOperasi" ok-title="Submit" @ok.prevent="submitJadwalOperasi" size="lg"
        no-close-on-backdrop class="text-center" :title="'Jadwalkan Operasi'">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th colspan="6">Informasi Pasien</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{rowEdit.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Tanggal Lahir / Umur</h4>
                  <p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Ruangan</h4>
                  <p>{{rowEdit.mranb_name||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Kamar / No. Tempat Tidur / Kelas</h4>
                  <p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Cara Bayar</h4>
                  <p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Alergi</h4>
                  <ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
                    <li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
                      {{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
                    </li>
                  </ul>
                  <span v-else>Tidak ada Alergi</span>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Diagnosa Masuk</h4>
                  <p>{{rowEdit.arano_diagnosa||"-"}}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-2">
          <div class="col-md-12 mt-2">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Jadwalkan Operasi</h6>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Ruang Operasi <strong class="text-danger">*</strong></label>
                      <v-select placeholder="Ruang Operasi" v-model="rowEdit.arano_ruang_operasi"
                        :options="mRuangOperasi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      <VValidate name="Ruang Operasi" v-model="rowEdit.arano_ruang_operasi" :rules="{required: 1}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Waktu Mulai Operasi<strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <datepicker input-class="form-control transparent" placeholder="Tanggal Mulai Operasi"
                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                          v-model="rowEdit.arano_tanggal_operasi_start">
                        </datepicker>
                        <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                      </div>

                      <VValidate name="Tanggal Mulai Operasi" v-model="rowEdit.arano_tanggal_operasi_start"
                        :rules="{required: 1}" />
                    </div>

                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="opacity-0">Jam Mulai Operasi<strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                        </div>
                        <vue-timepicker manual-input format="HH:mm" input-class="form-control"
                          v-model="rowEdit.arano_jam_operasi_start">
                        </vue-timepicker>
                      </div>

                      <VValidate name="Jam Mulai Operasi" v-model="rowEdit.arano_jam_operasi_start"
                        :rules="{required: 1}" />
                    </div>
                  </div>


                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Tanggal Berakhir Operasi<strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <datepicker input-class="form-control transparent" placeholder="Tanggal Mulai Operasi"
                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                          v-model="rowEdit.arano_tanggal_operasi_end">
                        </datepicker>
                        <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                      </div>

                      <VValidate name="Tanggal Berakhir Operasi" v-model="rowEdit.arano_tanggal_operasi_end"
                        :rules="{required: 1}" />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="opacity-0">Jam Berakhir Operasi<strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                        </div>
                        <vue-timepicker manual-input format="HH:mm" input-class="form-control"
                          v-model="rowEdit.arano_jam_operasi_end">
                        </vue-timepicker>
                      </div>
                      <VValidate name="Jam Berakhir Operasi" v-model="rowEdit.arano_jam_operasi_end"
                        :rules="{required: 1}" />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Perawat Asisten<strong class="text-danger">*</strong></label>
                      <v-select placeholder="Perawat Asisten" v-model="rowEdit.arano_perawat_asisten"
                        :options="mPerawatBedah" label="text" multiple :clearable="true" :reduce="v=>v.value">
                      </v-select>
                      <VValidate name="Perawat Asisten" v-model="rowEdit.arano_perawat_asisten"
                        :rules="{required: 1}" />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Perawat Instrument<strong class="text-danger">*</strong></label>
                      <v-select placeholder="Perawat Instrument" v-model="rowEdit.arano_perawat_instrumen"
                        :options="mPerawatBedah" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      <VValidate name="Perawat Instrument" v-model="rowEdit.arano_perawat_instrumen"
                        :rules="{required: 1}" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Perawat Anestesi<strong class="text-danger">*</strong></label>
                      <v-select placeholder="Perawat Anestesi" v-model="rowEdit.arano_perawat_anastesi"
                        :options="mPerawatBedah" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      <VValidate name="Perawat Anestesi" v-model="rowEdit.arano_perawat_anastesi"
                        :rules="{required: 1}" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <b-form-checkbox value="Y" unchecked-value="N" v-model="rowEdit.arano_is_delay"
                        class="form-check-input-styled" name="radio-inline-left">
                        Terdapat Penundaan Operasi
                      </b-form-checkbox>
                    </div>
                  </div>

                  <div class="col-md-4" v-if="rowEdit.arano_is_delay == 'Y'">
                    <div class="form-group">
                      <label>Keterangan </label>
                      <b-form-textarea v-model="rowEdit.arano_alasan_delay" rows="4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>
  </div>
  <div v-else>
    <template v-if="typeMenu == 'KajianPraBedah'">
      <KajianPraBedah />
    </template>
    <template v-if="typeMenu == 'RanapLaporanOperasi'">
      <RanapLaporanOperasi />
    </template>
    <template v-if="typeMenu == 'RanapCeklistKeselamatanAnestesi'">
      <RanapCeklistKeselamatanAnestesi />
    </template>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Datepicker from 'vuejs-datepicker'
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'

  import KajianPraBedah from './KajianPraBedah.vue'
  import RanapLaporanOperasi from './RanapLaporanOperasi.vue'
  import RanapCeklistKeselamatanAnestesi from './RanapCeklistKeselamatanAnestesi.vue'
  import ICD10Operasi from '@/components/Ranap/ICD10Operasi.vue'

  const moment = require('moment')

  
  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'

  export default {
      extends: GlobalVue,
      components:{
          Datepicker,VueTimepicker,DateRangePicker,KajianPraBedah,RanapLaporanOperasi,RanapCeklistKeselamatanAnestesi,ICD10Operasi
      },
      computed:{
          isDetail(){
              return this.$route.query.isDetail
          },
          isDoc(){
              return this.$route.query.isDoc
          },
          dokterOperator(){
            let dokter = []
            for(let i = 0; i < (this.rowEdit.arano_dokter_operator||[]).length; i++){
              dokter.push(this.rowEdit.arano_dokter_operator[i]['dokter'])
            }
            return dokter
          },
          typeMenu(){
            return this.$route.query.type
          }
      },
      data(){
          return {
              idKey:'aranres_id',
              datePickerConfig: {
                  startDate: new Date(moment().subtract(6, 'days')),
                  endDate: new Date(moment().add(6, 'days')),
                  autoApply: true,
                  ranges: {
                      'Hari Ini': [new Date(), new Date()],
                      '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                      '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                  },
                  applyClass: 'btn-sm btn-primary',
                  cancelClass: 'btn-sm btn-light',
                  locale: {
                      applyLabel: 'Terapkan',
                      cancelLabel: 'Batal',
                      direction: 'ltr',
                      format: 'mm/dd/yyyy',
                      separator: ' - ',
                  }
              },
              dateRange: {
                  startDate: new Date(moment().subtract(6, 'days')),
                  endDate: new Date(moment().add(6, 'days')),
              },
              mRuangOperasi: [],
              mPerawatBedah: [],
              mDPJP : [],
              rowEdit: {},
              openJadwalOperasi: false,
              operatorUtama: null,
              mOperasiStatus: [],
              openKajianOperasi: false,
              openPemeriksaan: false,
              rowPA: {},
              isReqKajianPAKlinis: false
          }
      },
      mounted(){
          this.filter.dpjp = 'ALL'
          
          this.doConvertDate()      
          this.apiGet()
          
          if(this.isList){
              this.apiGetResume()
          }
              
          setTimeout(()=>{
              this.setSlide()
          },1000)
      },
      methods: {
        
        jadwalkanOperasi(row){
          let data = row
          data.type = 'get-data'
          this.loadingOverlay = true
          Gen.apiRest(
              "/do/"+'RanapOperasiPerawatBedah',
              {data: data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false   
            this.openJadwalOperasi = true  
            this.rowEdit = resp.row
            this.operatorUtama = resp.row.arano_dokter_operator_utama
          })  
        },

        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowPA[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowPA[field] = data
          }
        },
        submitJadwalOperasi(){
          this.$refs['VFormSubmitJadwalOperasi'].validate().then(success=>{
            if(success){
              let data = this.rowEdit
              data.type = 'jadwalkan-operasi'
                     
              Gen.apiRest(
                  "/do/"+'RanapOperasiPerawatBedah',
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  let resp = res.data
                  this.loadingOverlay = false
                  this.$socket.emit('refresh_data_jadwal_operasi',{})
                  this.$swal({
                      title: resp.message,
                      icon: "success",
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      if(result.value){
                        this.openJadwalOperasi = false
                        this.apiGet()
                      }
                  })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })

            }
          })
        },
        
        submitJadwalPA(){
          this.$refs['VFormSubmitPA'].validate().then(success=>{
            if(success){
              if(!(this.rowPA.aranpjt_jaringan_tubuh||[]).length){
                return this.$swal({
                  icon: 'error',
                  title: 'Jaringan tubuh Minimal Diisi Satu',
                })
              }
              if(!(this.rowPA.aranpjt_direndam_dalam||[]).length){
                return this.$swal({
                  icon: 'error',
                  title: 'Direndam Dalam Minimal Diisi Satu',
                })
              }

              this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menyimpan data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
              }).then(result => {
                if (result.value) {
                    let data = this.rowPA

                    data.type = 'submit-data-pemeriksaan-tubuh'
                    this.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + this.modulePage, {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                          this.openPemeriksaan = false
                          this.apiGet()
                        })
                    }).catch(err => {
                        this.loadingOverlay = false
                        if (err) {
                            err.statusType = err.status
                            err.status = "error"
                            err.title = err.response?.data?.title
                            err.message = err.response?.data?.message
                            err.messageError = err.message
                        }
                        this.doSetAlertForm(err)
                    })
                }
              })
            }
          })
        },
        toModalPemeriksaan(row){
          this.rowEdit = row
          let data = this.rowEdit
          data.type = 'get-data-pemeriksaan-jaringan'
          this.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            this.rowPA = resp.row
            this.loadingOverlay = false
            this.openPemeriksaan = true
            
            setTimeout(()=>{
              $(".sign-container canvas").attr("width",400)
              $(".sign-container canvas").attr("height",160)                
              this.$nextTick(() => {
                if(this.$refs['ttdNama']){
                  this.$refs['ttdNama'].fromDataURL(this.rowPA.aranpjt_ttd)
                } 
              })
            },200)
          })  

        },

        doOpenKajianOperasi(row){
          this.rowEdit = row
          this.openKajianOperasi = true
        },
        toDetail(regID){
          this.$router.push({ name: 'RanapPasienOperasi', params: {pageSlug: regID}}).catch(()=>{})
        },
        toMenu(v, menu){
          this.$router.push(
          {
            name: this.modulePage, 
            params: {pageSlug : v.arano_id}, 
            query: {type : menu, regId: v.arano_aranr_id} 
          })
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                if(responsiveTableScroll){
                    // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    Array.from(responsiveTableScrollActions).forEach(action => {
                        action.addEventListener('click', e => {
                            const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                              
                            responsiveTable.scroll({
                                left: scrollAmount,
                                behavior: 'smooth'
                            })
                        })
                    })
                }
            }
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
              startDate: new Date(moment().subtract(6, 'days')),
              endDate: new Date(moment().add(6, 'days')),
            }
            this.doConvertDate()
        },
        doRefreshData(){
            this.apiGet()
            window.scrollTo(0,0)
        },
        apiGetResume(){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            Gen.apiRest(
                "/get/"+this.modulePage+'/resume', 
                {
                params: Object.assign({}, paramsQuery||{})
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },
        doPageOne(){
            this.filter.page = 1
        },
        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
      },
      filters: {
          date(val) {
              return val ? moment(val).format("D MMM YYYY") : ""
          }
      },
      watch:{
        $route(){
          this.apiGet()
          
          if(this.isList){
              this.apiGetResume()
          }
          
          setTimeout(()=>{
              if(this.isList){
                  this.setSlide()
              }
          },1000)

          this.openKajianOperasi = false
        },
        rowPA: {
          handler(v) {
            this.isReqKajianPAKlinis = $("#icd-10-op-"+'KajianPAKlinis').length ? false : true
          },
          deep: true
        },
      }
  }
</script>