<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />
    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          
          <div class="card">
            <div class="card-header bg-white">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <h5 class="card-title font-weight-semibold">Form Laporan Operasi</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Pra Operasi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-4 col-lg-2">
                          <div class="form-group">
                            <label for="perHub" class="mb-0">Dokter Bedah</label>
                            <p class="font-weight-semibold">{{row.dokter_operator||"-"}}</p>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <div class="form-group">
                            <label for="perHub" class="mb-0">Asisten Perawat</label>
                            <p class="font-weight-semibold">{{row.perawat_asisten||"-"}}</p>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <div class="form-group">
                            <label for="perHub" class="mb-0">Perawat Instrument</label>
                            <p class="font-weight-semibold">{{row.perawat_instrumen||"-"}}</p>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <div class="form-group">
                            <label for="perHub" class="mb-0">Dokter Anastesi</label>
                            <p class="font-weight-semibold">{{row.dokter_anestesi||"-"}}</p>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <div class="form-group">
                            <label for="perHub" class="mb-0">Perawat Anastesi</label>
                            <p class="font-weight-semibold">{{row.perawat_anestesi||"-"}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="spiNilai">Jenis Anestesi</label>
                            <p class="font-weight-semibold">{{row.arano_jenis_new||row.arano_jenis||"-"}}</p>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="spiNilai">Diagnosa Pra Bedah</label>
                            <p>{{row.arano_diagnosa||"-"}}</p>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="spiNilai">Tindakan Operasi</label>
                            <p>{{row.arano_tindakan_operasi||"-"}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Intra Operasi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-4 col-lg-6">
                          <div class="form-group">
                            <label>Waktu Mulai Operasi<strong class="text-danger">*</strong></label>
                            <div class="form-row">
                              <div class="col-lg-6">
                                <div class="input-group">
                                    <datepicker input-class="form-control transparent"
                                        placeholder="Tanggal Mulai Operasi" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar"
                                        v-model="row.aranlo_start_operasi_tanggal">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                    </div>
                                </div>
  
                                <VValidate 
                                  name="Tanggal Mulai Operasi" 
                                  v-model="row.aranlo_start_operasi_tanggal" 
                                  :rules="{required: 1}"
                                />
                              </div>
  
                              <div class="col-lg-6">
                                <div>
                                  <label class="opacity-0 d-none">Jam Mulai Operasi<strong class="text-danger">*</strong></label>
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                    </div>
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranlo_start_operasi_jam">
                                    </vue-timepicker>
                                  </div>
                                  
                                  <VValidate 
                                    name="Jam Mulai Operasi" 
                                    v-model="row.aranlo_start_operasi_jam" 
                                    :rules="{required: 1}"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-6">
                          <div class="form-group">
                            <label>Tanggal Berakhir Operasi<strong class="text-danger">*</strong></label>
                            <div class="form-row">
                              <div class="col-lg-6">
                                <div class="input-group">
                                    <datepicker input-class="form-control transparent"
                                        placeholder="Tanggal Mulai Operasi" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar"
                                        v-model="row.aranlo_end_operasi_tanggal">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                    </div>
                                </div>
                                
                                <VValidate 
                                  name="Tanggal Berakhir Operasi" 
                                  v-model="row.aranlo_end_operasi_tanggal" 
                                  :rules="{required: 1}"
                                />
                              </div>
                          
                              <div class="col-lg-6">
                                <div>
                                  <label class="opacity-0 d-none">Jam Berakhir Operasi<strong class="text-danger">*</strong></label>
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                    </div>
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranlo_end_operasi_jam">
                                    </vue-timepicker>
                                  </div>
                                  <VValidate 
                                    name="Jam Berakhir Operasi" 
                                    v-model="row.aranlo_end_operasi_jam" 
                                    :rules="{required: 1}"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="perHub">Lama Operasi<strong class="text-danger">*</strong></label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i class="icon-alarm"></i></span>
                              </div>
                              <input v-model="longTimeOperasi" type="text" class="form-control" disable readonly>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-5">
                          <div class="form-group">
                            <label for="spiNilai">Desinfeksi kulit dengan</label>
                            <b-form-input v-model="row.aranlo_desinfeksi_kulit" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-input>
                          </div>
                        </div>

                        <div class="col-md-5">
                          <div class="form-group">
                            <label for="spiNilai">Jaringan yang dieksisi</label>
                            <b-form-input v-model="row.aranlo_jaringan_dieksisi" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-input>
                          </div>
                        </div>
                        
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="spiNilai">Dikirim ke PA</label>
                            <b-form-radio-group
                              class="custom-block"
                              :options="Config.mr.yesNoOptV2"
                              v-model="row.aranlo_is_pa"
                            />
                            <VValidate 
                                name="Dikirim ke PA" 
                                v-model="row.aranlo_is_pa" 
                                :rules="toValidate(mrValidation.aranlo_is_pa)"
                            />
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Cara Approach </label>
                            <b-form-input v-model="row.aranlo_cara_approach" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-input>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Posisi Penderita </label>
                            <b-form-input v-model="row.aranlo_posisi_penderita" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-input>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Durante Operationen</label>
                            <b-form-textarea v-model="row.aranlo_durante" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                          </div>
                        </div>
                        
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">File Durante Operationen</label><br/>
                            <button type="button" @click="doOpenDuranteOptinen()" class="btn btn-primary mr-1">Gambar Durante Optinen <i class="icon-file-text3 ml-2"></i></button><br/>
                            <small v-if="row.aranlo_durante_update">Terakhir Diupdate : {{row.aranlo_durante_update | moment("DD MMMM YYYY, HH:mm") }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Post Operasi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-6" v-if="row.arano_id">
                          <ICD10Operasi title="Diagnosa ICD 10 Pasca Bedah" type="LaporanOperasi" :idOp="row.arano_id" />
                          <div class="form-group p-3 custom-diagnosa">
                            <label for="spiNilai">Diagnosa Pasca Bedah</label>
                            <b-form-textarea v-model="row.aranlo_diagnosa_pasca_bedah" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="row.arano_id">
                          <ICD9Operasi title="Tindakan ICD 9 Pasca Bedah" type="LaporanOperasi" :idOp="row.arano_id" />                
                          <div class="form-group p-3 custom-diagnosa">
                            <label for="spiNilai">Tindakan Operasi</label>
                            <b-form-textarea v-model="row.aranlo_diagnosa_jenis_operasi" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                          </div>
                        </div>

                        <!--
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Perjalanan operasi yang terinci dan lengkap</label>
                            <b-form-textarea v-model="row.aranlo_perjalanan_operasi" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                          </div>
                        </div>
                        -->
                       
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Penggolongan Operasi</label>
                            <b-form-group class="mb-0">
                                <b-form-checkbox-group
                                    id="aranlo_macam_operasi"
                                    :options="Config.mr.macamOperasi"
                                    v-model="row.aranlo_macam_operasi"
                                    name="aranlo_macam_operasi"
                                ></b-form-checkbox-group>
                            </b-form-group>
                          </div>
                          
                          <div class="form-group">
                            <label for="spiNilai">Kegawatan Operasi</label>
                            <b-form-radio-group
                              class="radio-inline"
                              :options="Config.mr.kegawatanOperasi"
                              v-model="row.aranlo_kegawatan_operasi"
                            />
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="spiNilai">Jumlah Perdarahan</label>
                            <div class="input-group mt-2">
                              <b-form-input :formatter="numberOnly" v-model="row.aranlo_perdarahan" type="text" class="form-control" />
                              <div class="input-group-append"><span class="input-group-text">cc</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Intruksi Pasca Bedah</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Instruksi Pasca Bedah</label>
                            <b-form-textarea v-model="row.aranlo_intruksi_pasca_bedah" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>            
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="spiNilai">Kontrol</label>
                            <b-form-group class="mb-0">
                                <b-form-checkbox-group
                                    id="aranlo_kontrol"
                                    :options="Config.mr.ranapLaporanKontrol"
                                    v-model="row.aranlo_kontrol"
                                    name="aranlo_kontrol"
                                ></b-form-checkbox-group>
                            </b-form-group>
                          </div>
                        </div>

                        <div class="col-md-6" v-if="(row.aranlo_kontrol||[]).indexOf('Puasa') != -1">
                          <div class="form-group">
                            <label for="spiNilai">Puasa <strong class="text-danger">*</strong></label>
                            <b-form-textarea v-model="row.aranlo_puasa" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>

                            <VValidate 
                              name="Puasa" 
                              v-model="row.aranlo_puasa" 
                              :rules="{required: 1, min: 2, max:128}"
                            />
                          </div>
                        </div>

                        <div class="col-md-6" v-if="(row.aranlo_kontrol||[]).indexOf('Infus') != -1">
                          <div class="form-group">
                            <label for="spiNilai">Infus <strong class="text-danger">*</strong></label>
                            <b-form-textarea v-model="row.aranlo_infus" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>

                            <VValidate 
                              name="Infus" 
                              v-model="row.aranlo_infus" 
                              :rules="{required: 1, min: 2, max:128}"
                            />
                          </div>
                        </div>

                        <div class="col-md-6" v-if="(row.aranlo_kontrol||[]).indexOf('Antibiotika') != -1">
                          <div class="form-group">
                            <label for="spiNilai">Antibiotika <strong class="text-danger">*</strong></label>
                            <b-form-textarea v-model="row.aranlo_antibiotika" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                            
                            <VValidate 
                              name="Antibiotika" 
                              v-model="row.aranlo_antibiotika" 
                              :rules="{required: 1, min: 2, max:128}"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-1">Back</button>
                <button type="button" @click="unduhDokumen" class="btn btn-primary mr-1">Unduh Dokumen <i class="icon-file-text3 ml-2"></i></button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                <br/>                
                <small class="mt-2" v-if="row.aranlo_updated_date">Terakhir Diupdate : {{row.aranlo_updated_date | moment("DD MMMM YYYY, HH:mm") }}</small>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>

    <b-modal v-model="duranteOptinen" :title="'Isi Durante Optinen'" size="xl" ok-only>
      <div class="row">
        <div class="col-md-7">
          <div class="form-row">
            <div class="col-md" style="min-height: 650px;" ref="contentToConvert">
              <div class="fsg-main" style="border: 1px solid #ccc;">
              <VueSignaturePad
                  class="sign-data"
                  ref="signaturePad"
                  :options="options"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5" id="sidebar">
          <div class="colorButtons form-group">
            <label>Warna</label>
            <div class="d-flex row">
              <b-form-input type="color" class="colorpicker col-md-3 ml-2" v-model="hexColor" placeholder="e.g 0001"/>
              <a href="javascript:;" @click="changeColor" class="btn btn-info mr-1 ml-2 col-md-3">Ganti Warna</a>
            </div>
          </div>

          <div class="extra form-group">
            <label>Aksi</label>
            <div>
              <a href="javascript:;" @click="undoSign('signaturePad','aranlo_durante_file')" class="btn btn-warning mr-1">Undo</a>
              <a href="javascript:;" @click="undoTTD('signaturePad','aranlo_durante_file')" class="btn btn-danger mr-1">Clear</a>
              <a href="javascript:;" @click="outputSign('signaturePad','aranlo_durante_file')" class="btn btn-success mr-1">Save</a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import ICD10Operasi from '@/components/Ranap/ICD10Operasi.vue'
import ICD9Operasi from '@/components/Ranap/ICD9Operasi.vue'


export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapLaporanOperasi',
      patientData: {},
      loading: {
          patientInfo: false,
      },
      duranteOptinen: false,
      options: {
        penColor: "#000",
        backgroundColor: 'rgba(0,0,0,0)',
      },
      hexColor: "#000",
    }
  },
  components:{
    Datepicker,VueTimepicker,PatientInfo,ICD10Operasi,ICD9Operasi
  },
  mounted() {
    this.apiGet()
    this.getPatientInfo()
  },
  computed: {
    longTimeOperasi(){
      let startDate = moment(moment(this.row.aranlo_start_operasi_tanggal).format("YYYY-MM-DD") + ' ' + this.row.aranlo_start_operasi_jam)
      let endDate = moment(moment(this.row.aranlo_end_operasi_tanggal).format("YYYY-MM-DD") + ' ' + this.row.aranlo_end_operasi_jam)
      let durationInMinutes = endDate.diff(startDate, 'minutes') // Menghitung selisih waktu dalam menit
      let hours = Math.floor(durationInMinutes / 60) // Menghitung jumlah jam
      let minutes = durationInMinutes % 60 // Menghitung sisa menit
      let longTime = ''
      if (isNaN(durationInMinutes)) {
        longTime = '0 Jam 0 Menit' // Jika tidak valid, mengembalikan 0 jam 0 menit
      } else {
        longTime = hours + ' Jam ' + minutes + ' Menit' // Jika valid, mengembalikan jumlah jam dan menit
      }
      return longTime
    }
  },

  methods: {

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },

    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiDokterOperator'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },
    unduhDokumen(){
      let self = this
      let data = {exptype: 'xlsx', type: "export", id: self.row.arano_id}
      // self.loadingOverlay = true
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'downloadLaporanOperasi'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan_Operasi_${self.row.ap_fullname}_${self.row.ap_code}_${moment().format("YYYY-MM-DD")}.pdf`
              link.click() 
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
    
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
      setTimeout(()=>{
        this.autoSave(this.row,true)
      },100)
    },
    undoSign(refs,field){
      this.$refs[refs].undoSignature()
      this.output(refs, field)
    },
    outputSign(refs,field){
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
        setTimeout(()=>{
          this.autoSave(this.row,true)
        },100)
      }
      this.row['aranlo_durante_update'] = moment().format("YYYY-MM-DD HH:mm:ss")

      // let self = this      
      // html2canvas(self.$refs['contentToConvert']).then(canvas => {  
      //   let data = canvas.toDataURL()
      //   self.row['aranlo_durante_update'] = moment().format("YYYY-MM-DD HH:mm:ss")
      //   self.row[field+'_with_image'] = data
      //   self.autoSave(self.row,true)
      // })

    },
    
    changeColor() {
      this.options = {
        penColor: this.hexColor,
      }
      return this.$swal({
        icon: 'success',
        title: 'Berhasil Mengganti Warna'
      })
    },
    doOpenDuranteOptinen(){
      this.duranteOptinen = true
      setTimeout(()=>{
        $(".sign-data canvas").attr("width",632)
        $(".sign-data canvas").attr("height",600)  
        this.$nextTick(() => {
          if(this.row.aranlo_durante_file){
            if(this.$refs['signaturePad']){
              this.$refs['signaturePad'].fromDataURL(this.row.aranlo_durante_file)
            }
          }
        })
      })
    },

  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    },

  }
}
</script>