<template>
  <div class="card border shadow-0 mb-0">
    <div class="bg-light card-header py-2">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 font-weight-semibold">{{title}}</h6>
    </div>
    </div>
    <div class="card-body">
    <div class="form-group mb-0">
        <div class="input-group">
        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
            <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                v-model="inputICD9"
                @search="filterICD9($event,0)"
                @input="selectICD9(inputICD9)"
                :options="mICD9" label="text"
                :clearable="true" :reduce="v=>v.value">
                
                <template slot="selected-option">
                <span v-b-tooltip.hover.right :title="inputICD9Name">
                    {{inputICD9Name||"-"}}
                </span>
                </template>
                
                <template slot="option" slot-scope="option">
                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                </template>
                <template #list-footer v-if="(mICD9||[]).length >= 1">
                <li class="list-paging">
                    <div class="d-flex align-items-center justify-content-between">
                    <b-button @click="changePageICD10(mICD9[0].currentPage,0,'min','mICD9')"
                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                        class="icon-arrow-left22 text-white"></i></b-button>
                    <small>Halaman {{mICD9[0].currentPage}} dari
                        {{ceilData(mICD9[0].totalRows/5)}}</small>
                    <b-button @click="changePageICD10(mICD9[0].currentPage,0,'plus','mICD9')"
                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                        class="icon-arrow-right22 text-white"></i></b-button>
                    </div>
                </li>
                </template>
            </v-select>
        </div>
        </div>
    </div>
    </div>
    <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
    <thead>
        <tr>
        <th>Item ICD-9 dipilih</th>
        <th width="64">Aksi</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(v,k) in (dataICD9||[])" :key="k">
        <td><span>{{v.mi9_name||"-"}}<span class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
        <td>
            <a href="javascript:;" @click="removedataICD9(v.aranicd9o_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
            <i class="icon-trash-alt"></i>
            </a>
        </td>
        </tr>
    </tbody>
    </table>
    <div class="card-body py-2" v-else>
    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
    </div>
  </div>
</template>

<script>

import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

import $ from 'jquery'
const _ = global._

export default {
  extends: GlobalVue,
  props: {
    title: String,
    type: String,
    idOp: Number,
    pasienId: Number,
  },
  data() {
    return {
      menuPage: "RanapICD9Operasi",

      mICD9: [],
      dataICD9: [],
      inputICD9: null,
      riwayatICD9: [],
      openriwayatICD9: false,      
      selectedICD9: '',
      searchICD9: '',
      inputICD9Name: null,
      searchICD9Name: null,
    }
  },
  methods: {
    
    ceilData(value) {
        return Math.ceil(value)
    },

    apiGet(params = {}, page = 1){
      this.loadingOverlay = true
      let url = this.menuPage+'/'+this.idOp+'?typeOperasi='+this.type      
      Gen.apiRest(
        "/get/"+url
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
      }).catch(()=>{
        this.loadingOverlay = false
      })

      // master
      Gen.apiRest(
          "/get/"+this.menuPage+'/'+this.idOp+'?master=1'
      ).then(res=>{
          console.log(res.data)
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
      })
    },
    
    filterICD9: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD9 || []).length; i++) {
            if (this.dataICD9[i]['aranicd9o_icd_9']) {
                id.push(this.dataICD9[i]['aranicd9o_icd_9'])
            }
        }
        
        this.searchICD9Name = e

        Gen.apiRest('/do/' + this.menuPage, {
            data: {
                type: 'select-icd-9',
                search: e,
                id: id
            }
            }, 'POST').then(res => {
            this.mICD9 = res.data.data
        })
    }, 10),
    
    selectICD9(e) {
        let index = this.mICD9.findIndex(x => x.value == e)
        if (index !== -1) {
            this.inputICD9Name =  this.mICD9[index]['text']
            this.searchICD9Name = null
        }else{
            this.inputICD9Name =  null
        }
        this.addNewICD10()
    },
    changePageICD10(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
        }
        
        let data = {
            type: 'select-paging-icd-9',
            page: page,
            search: this.searchICD9Name
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.mICD9 = res.data.data
            this.getmICD9(this.dataICD9, k)
        })
    },

    addNewICD10() {
        if(this.inputICD9){
        // if (!this.row.isEdit) {
        let data = {
            aranicd9o_arano_id: this.idOp,
            aranicd9o_icd_9: this.inputICD9,
            aranicd9o_type: this.type,
            type: 'add-icd-9'
        }
        Gen.apiRest(
            "/do/" + this.menuPage, {
            data: data
            },
            "POST"
        ).then(res => {
            let resp = res.data
            this.dataICD9.push(resp.row)
            this.inputICD9 = null
        })
        // } else {
        //   let data = {
        //     aranicd9o_arano_id: this.idOp,
        //     aranicd9o_icd_9: this.inputICD9,
        //   }
        //   this.dataICD9.push(data)
        // }
        }
    },
    removedataICD9(id, k) {
        // if (!this.row.isEdit) {
        let data = {
        id: id,
        type: 'remove-icd-9'
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(() => {
        this.dataICD9.splice(k, 1)
        })
        // } else {
        //   this.dataICD9.splice(k, 1)
        // }
    },

    getmICD9(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
      return k2 != k
      }), v2 => {
      return v2.api10_icd_9
      })
      return _.filter(this.mICD9, v2 => {
      return sldMenu.indexOf(v2.value) <= -1
      })
    },
    
    autoSaveICD10: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-icd-9',
        data: data
      }
      Gen.apiRest(
        "/do/" + this.menuPage, {
          data: dataPost
        },
        "POST"
      )
    }, 1000),
  },
  mounted() {
    this.apiGet()
  },
  watch: {  
    dataICD9: {
      handler(v) {
        this.autoSaveICD10(v)
      },
      deep: true
    },
  }
  
}

</script>